/// <reference path="../../../../../node_modules/@types/jquery/index.d.ts" />
/// <reference path="../../../Cider.Framework/Assets/TypeScript/form-validation.ts" />
class ModalHelper
{
    constructor(private modal: JQuery, private modalSubmitButton: JQuery = null) {
        this.init();
    }

    private init(): void {
        this.modal.on("show.bs.modal", (event: Event) => {
            this.createSnapshot();
        });

        this.modal.on("hidden.bs.modal", (event: Event) => {
            this.revertSnapshot();
        });

        if (this.modalSubmitButton != null && this.modalSubmitButton.length > 0) {
            this.addEnterKeyHandler(this.modalSubmitButton);
        }
    }

    private getInputs(): JQuery {
        return this.modal.find(".modal-body :input");
    }

    public createSnapshot(): void {
        this.getInputs().each((index: number, input: Element) => {
            var i = $(input);
            i.attr('data-prev', i.val());
            if (i.is('[type="checkbox"]')) {
                i.attr("data-prev-checked", String(i.is(":checked")));
            }
        });
    }

    public revertSnapshot(): void {
        this.getInputs().each((index: number, input: Element) => {
            var i = $(input);
            if (i.is('[data-prev]')) {
                i.val(i.attr('data-prev'));
            }
            if (i.is('[type="checkbox"]')) {
                i.prop("checked", i.attr("data-prev-checked") === String(true));
            }            
        });
    }

    public validate(): boolean {
        return (<any>this.modal
            .validate({ errorPlacement: () => true }))
            .subset('#' + this.modal.attr('id') + ' .modal-body');
    }

    public getData(): any {
        var data = {};
        $.each(this.getInputs().serializeArray(), (index: number, element: JQuerySerializeArrayElement) => {
            let prefix: string = this.modal.attr('data-prefix');
            let name: string;
            if (prefix) {
                name = element.name.replace(prefix, 'Modal');
            }
            else {
                name = element.name;
            }

            data[name] = element.value;
        });
        return data;
    }

    public show(): void {
        this.modal.modal('show');
    }

    public save(): void {
        this.createSnapshot();
        this.modal.modal('hide');
    }

    public cancel(): void {
        // By not refreshing the snapshot the hide
        // event will revert the modal to the old values
        this.modal.modal('hide');
    }

    private addEnterKeyHandler(modalSubmitButton: JQuery) {
        this.modal.on('keypress', (e: JQueryEventObject) => {
            // If the user hits the enter key when an input in the ship-to modal is focused,
            // it will try to submit the whole landing page. We want to prevent this and trigger
            // a click on the modal submit button instead. Default behavior is fine for select fields,
            // since the enter button will just open the dropdown
            if (e.key === 'Enter' && e.target instanceof HTMLInputElement && !(e.target instanceof HTMLSelectElement)) {
                e.preventDefault();
                modalSubmitButton.trigger('click');
            }
        });
    }
}